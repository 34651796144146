<template>
  <div>
    <b-row v-if="width<768">
      <b-col md="12">
        <b-button v-b-modal.modal-hierachy>
          <feather-icon icon="GitPullRequestIcon" />
        </b-button>
      </b-col>
    </b-row>
    <b-card>
      <!-- FILTER START -->
      <b-row>
        <b-col md="3">
          <b-form-group :label="$t('labels.provider')">
            <v-select
              :value="$store.value"
              v-model="filterSelect.provider"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="providerOptions"
              label="key"
              @input="inputSelect"
              :reduce="(provider) => provider.value"
              :placeholder="this.$t('select_supplier')"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group :label="$t('labels.category')">
            <v-select
              :value="$store.value"
              v-model="filterSelect.category"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="categoryOptions"
              label="key"
              @input="inputSelect"
              :reduce="(category) => category.value"
              :placeholder="this.$t('select_category')"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group :label="$t('labels.currency')">
            <v-select
              :value="$store.value"
              v-model="filterSelect.currency"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="currencyOptions"
              label="key"
              @input="inputSelect"
              :reduce="(currency) => currency.value"
              :placeholder="this.$t('select_currency')"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group :label="$t('labels.datefrom')" rules="required">
            <flat-pickr
              v-model="filterSelect.dateFrom"
              @input="inputSelect"
              class="form-control"
              :config="{
                            altInput: true,
                            altFormat: 'd-m-Y H:i',
                            enableTime: true,
                            time_24hr: true,
                        }"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group :label="$t('labels.dateto')" rules="required">
            <flat-pickr
              v-model="filterSelect.dateTo"
              @input="inputSelect"
              class="form-control"
              :config="{
                            altInput: true,
                            altFormat: 'd-m-Y H:i',
                            enableTime: true,
                            time_24hr: true,
                        }"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <!-- FILTER END -->
    </b-card>
    <b-row>
      <b-col md="10">
        <b-card>
          <b-overlay :show="loadingData" variant="transparent" no-wrap />
          <b-modal
            id="modalResponse"
            title="PopUp Response"
            cancel-variant="outline-secondary"
            scrollable
          >
            <b-card-text>
              {{ rows }}
            </b-card-text>
          </b-modal>
          <!-- table -->
          <b-table
            md="9"
            :items="dataItems"
            :fields="fields"
            class="scroll"
            responsive
          >
          </b-table>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted"
                  >{{ $t('labels.showing') }} {{ "-" }} {{ $t('labels.to') }}
                  {{
                                        "-"
                  }}
                  {{ $t('labels.of') }} {{ "-" }}
                  {{ $t('labels.entries') }}</span
                >
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="serverParams.page"
                  :total-rows="totalRows"
                  :per-page="serverParams.limit"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                  :simple="true"
                  :hide-goto-end-buttons="true"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
      <b-modal
        id="modal-hierachy"
        hide-footer
        title="Listado de agentes"
        v-if="width<768"
      >
        <HierarchyUsers @selectUser="showData" ref="HerarchiComponent" />
      </b-modal>
      <b-col class="p-0" md="2" v-else>
        <b-card no-body class="p-0 py-1">
          <HierarchyUsers @selectUser="showData" />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BTable,
    BOverlay,
    BPagination,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BInputGroup,
    BCollapse,
    VBToggle,
    VBModal,
    BCardText,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { VueGoodTable } from "vue-good-table";
import store from "@/store";
import axios from '@axios';
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment";
import HierarchyUsers from "@core/components/HierarchyUsers/HierarchyUsers.vue";
import {translatableText} from "@core/utils/utils";
import {axiosErrorHandle} from "@core/utils/errorHandler";

export default {
    components: {
        BCard,
        BRow,
        BCol,
        BTable,
        BOverlay,
        BPagination,
        BFormSelect,
        BFormGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
        BInputGroup,
        BCollapse,
        VBModal,
        BCardText,
        VBToggle,
        vSelect,
        VueGoodTable,
        flatPickr,
        ToastificationContent,
        HierarchyUsers
    },
    directives: {
        "b-toggle": VBToggle,
        "b-modal": VBModal,
        Ripple,
    },
    data() {
        return {
            fields: [
              {label: this.$t('product'), key: "origen"},
              {label: this.$t('ticket_issued'), key: "referenceId"},
              {label:  this.$t('labels.amount'), key: "user"},
                { label: this.$t('labels.paid_ticket'), key: "date" },
                { label: this.$t('labels.amount'), key: "ticketStatus" },
                { label: this.$t('profit'), key: "amount" },
            ],
            loadingData: false,
            dataItems: [],
            dataIdUser: [],
            pages: [20, 40, 60],
            search: "",
            rows: [],
            serverParams: {
                page: 1,
                limit: 20,
            },
            totalRows: 0,
            providerOptions: [
                { key: this.$t('buttons.all'), value: "" },
                { key: `${ this.$t('labels.supplier')} 1`, value: "provider1" },
            ],
            categoryOptions: [
                { key: "Todos", value: "" },
                { key: "Categoria 1", value: "category1" },
            ],
            currencyOptions:[
                { key: this.$t('buttons.all'), value: "" },
                { key: "USD $", value: "$" },
            ],
            filterSelect: {
                ticketId: "",
                selectType: "",
                type: "",
                dateFrom: moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
                dateTo: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
            },
            dataReportHistory: [],
            width: 0,
        };
    },

    // add filters
    filters: {
        moment: function (date) {
            return moment(date).format("MM/DD/YYYY HH:mm:ss");
        },
    },

    methods: {
        /*List - Index*/
        async showData({ id }) {
            this.dataIdUser = id
            try {
                this.loadingData = true;
                const VUE_APP_URL = process.env.VUE_APP_URL
                const params = {
                    "id": id,
                    "whitelabel": this.$store.state.whitelabelCurrencyNabvar.whitelabel._id,
                    "clientId": this.$store.state.whitelabelCurrencyNabvar.whitelabel.client,
                    "currency": this.$store.state.whitelabelCurrencyNabvar.currency,
                    "ticketId": this.filterSelect.ticketId,
                    "type": this.filterSelect.type,
                    "dateFrom": this.filterSelect.dateFrom,
                    "dateAt": this.filterSelect.dateTo,
                }

                console.log(`URL: ${VUE_APP_URL}/sw3/list`)
                console.log('Parametros Enviados:', params)

                const result = axios
                    .post(`${VUE_APP_URL}/sw3/list`, params)
                    .then(r => {
                        this.dataItems = r.data;
                        console.log('Respuesta:', this.dataItems)
                    })
                    .catch(error => console.log('Error SW3 List', error.response))



                return result

            } catch (error) {
                console.log(error);
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: await translatableText({text: axiosErrorHandle(error)}),
                        icon: "AlertTriangleIcon",
                        variant: "danger",
                    },
                });
            } finally {
                this.loadingData = false;
            }
        },

        async modalSearchTicketId() {

          try {
            this.loadingData = true;
            const VUE_APP_URL = process.env.VUE_APP_URL
            const result = axios
                .get(`${VUE_APP_URL}/sw3/` + this.filterSelect.ticketId + `/detail`)
                .then(r => {
                  const searchId = r.data.referenceId
                  console.log('RESPONSEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE:', r)

                  this.$router.push({name: 'apps-ticket-sports-live-detail', params: {id: searchId}});
                })
                .catch(async error => {
                  console.log('Error Encontrado:', error.response)
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: await translatableText({text: axiosErrorHandle(error)}),
                      icon: "AlertTriangleIcon",
                      variant: "danger",
                    },
                  });
                })

            return result

          } catch (error) {
            console.log(error);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: await translatableText({text: axiosErrorHandle(error)}),
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          } finally {
            this.loadingData = false;
          }
        },
        onPageChange(page) {
            console.log(page, "page");
            this.serverParams.page = page;
            this.showData();
        },
        onPerPageChange(limit) {
            this.serverParams.page = 1;
            this.serverParams.limit = limit;
            this.showData();
        },
        inputSelect() {
            let id = this.dataIdUser
            this.showData({ id });
        },

    },
    created() {
        this.width = window.innerWidth;
        window.addEventListener('resize', () => {
            this.width = window.innerWidth;
        });
    },
    mounted() {
    },
    destroyed() {
        if (store.hasModule("reports")) store.unregisterModule("reports");
    },
};
</script>

<style lang="scss">
.per-page-selector {
    width: 90px;
}

.scroll::-webkit-scrollbar {
    -webkit-appearance: none;
}

.scroll::-webkit-scrollbar-button:increment,
.scroll::-webkit-scrollbar-button {
    display: none;
}

.scroll::-webkit-scrollbar:horizontal {
    height: 10px;
}

.scroll::-webkit-scrollbar-thumb {
    background-color: #797979;
    border-radius: 20px;
}

.scroll::-webkit-scrollbar-track {
    border-radius: 10px;
}

@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
